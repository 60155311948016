<template>
  <button
    class="
      agent-card
      tw-w-full
      tw-border
      tw-flex
      tw-flex-col
      tw-justify-start
      tw-items-start
      tw-p-2
      tw-my-2
      tw-relative
      tw-rounded-lg
    "
    :class="!active ? 'tw-cursor-not-allowed' : ' hover:tw-bg-slate-100'"
    @click="onClickAgent"
  >
    <p class="tw-font-bold">Name: {{ name }}</p>
    <p>ID: {{ id }}</p>
    <span
      class="
        tw-text-xs
        tw-inline-block
        tw-py-1
        tw-px-2.5
        tw-leading-none
        tw-text-center
        tw-whitespace-nowrap
        tw-align-baseline
        tw-font-bold
        tw-text-white
        tw-rounded
        tw-absolute
        tw-top-2
        tw-right-2
      "
      :class="active ? 'tw-bg-green-400' : 'tw-bg-red-400'"
      >{{ active ? "Online" : "Offline" }}</span
    >
  </button>
</template>

<script>
export default {
  name: "AgenCard",
  props: ["agent"],
  data() {
    return {};
  },
  computed: {
    name() {
      return this.agent.Name || "-";
    },
    id() {
      return this.agent.AgentID || "-";
    },
    active() {
      return this.agent.IsActive;
    },
  },
  methods: {
    onClickAgent() {
      if (!this.active) return;
      this.$emit("select-agent");
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px !important;
}

.agent-card {
  border: 1px solid rgb(215, 215, 215) !important;
}

input {
  border: 1px solid rgb(215, 215, 215) !important;
}
</style>
